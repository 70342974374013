<template>
    <div class="main">
        
        <van-popup v-model:show="istan" :close-on-click-overlay="false" round style="min-width:90vw;min-height:40vh;">
            
            <van-cell-group style="padding-top:25px;">
                <van-field v-show="control_nsrmc" :model-value="nsrmc" readonly label="企业名称:"  placeholder="纳税人名称" />
                <van-field v-show="control_nsrsbh" v-model="formObj.nsrsbh" label="纳税人:" required placeholder="请输入纳税人识别号" />
                <van-field v-model="formObj.czry_dm" label="操作人:" required placeholder="请输入操作人代码" />
                <van-field v-model="formObj.psw"  type="password" label="密 码:" placeholder="请输入密码" required />
            </van-cell-group>

            <div style="margin:25px 0px;text-align:center">
                <van-button round  type="primary" plain style="margin-right: 16px;width:90px;"  @click="quXiao">取  消</van-button>
                <van-button round  type="primary" style="width:90px;" @click="onSubmit">提 交</van-button>
            </div>
                
        </van-popup>
        <div v-if="!istan">
            <div style="margin-bottom:20px;" v-for="(item,index) in Identity" :key="index">
                <van-cell-group inset style="margin:0;box-shadow:4px 4px 10px #eee; border:1px solid #eee;">
                    <van-cell center :clickable="true" @click="DQclk(item,index)" class="citem" >
                        <template #title>
                            <div class="qjListItem">
                                <span class="spanTitle">纳税人名称：</span>
                                <span class="spanVLE">{{item.NSRMC}}</span>
                            </div>
                            <div class="qjListItem">
                                <span class="spanTitle">税务机关：</span>
                                <span class="spanVLE">{{item.SSJG}}</span>
                            </div>
                            <div class="qjListItem">
                                <span class="spanTitle">生产经营地址：</span>
                                <span class="spanVLE">{{item.SCJYDZ}}</span>
                            </div>
                        </template>
                    </van-cell>
                </van-cell-group>
            </div>
            <div style="margin:40px 0;">
                <van-button block  type="primary"  @click="bdClik">提 交</van-button>
            </div>
        </div>

    </div>
</template>

<script>
import { ref, getCurrentInstance} from 'vue'
import {Toast } from 'vant';
import loginRequest from '../../api/login'
import {useStore} from "vuex";
import {getLocalStorage,setLocalStorage} from "../../utils/local-storage";
import config from "../../config";
import getUserInfo from '@/mixin/getUserInfo.js'


export default {
    name: "intellectBS",
    setup() {
        const {proxy} = getCurrentInstance();
        const store = useStore();
        const istan = ref(true);
        const formObj = ref({
            czry_dm:"",
            nsrsbh:"",
            psw:""
        });
        const sbdata = sessionStorage.getItem("sy-sbxx-data")!== undefined? JSON.parse(sessionStorage.getItem("sy-sbxx-data")): undefined;

        const control_nsrmc = ref(false);
        const control_nsrsbh = ref(false);

        const nsrArr = store.state.nsrUserArr;
        const nsrmc = ref("");
        if(nsrArr!=null&&nsrArr.length>0){
          const t_mryh = nsrArr[0].mryh;
          for (let i=0;i<nsrArr.length;i++){
            if(t_mryh == nsrArr[i].mryh){
              nsrmc.value = nsrArr[i].merchant_name;
              break;
            }
          }
        }

        control_nsrsbh.value = true;
        control_nsrmc.value = false;
        let t_auth = getLocalStorage(config.request.localKey);
        if(t_auth!=undefined){
          let t_auth_infos = t_auth[config.request.localKey];
          if(t_auth_infos!="undefined" && t_auth_infos!="null"){
            t_auth_infos = JSON.parse(t_auth_infos);
            if(t_auth_infos!=undefined&&t_auth_infos.length>0){
              let t_auth_info = t_auth_infos[0];
              console.log(t_auth_info);
              if(t_auth_info.yhlx == "sb"){
                control_nsrsbh.value = true;
                control_nsrmc.value = false;
              }else{
                control_nsrsbh.value = false;
                control_nsrmc.value = true;
              }
            }
          }
        }

        const Identity=ref([]);
        const onSubmit = () => {
            // console.log('submit', formObj);
            if(sessionStorage.getItem("isDenglu") == "true"){

            }else{
              if(formObj.value.nsrsbh==""){
                Toast("纳税人识别号不能为空");
                return
              }
            }
            if(formObj.value.czry_dm==""){
                Toast("操作人代码不能为空");
                return
            }
            if(formObj.value.psw==""){
                Toast("密码不能为空");
                return
            }
            loginRequest.yh_qrsf(formObj.value).then(res=>{
                if (res.returnCode == "00"){
                    Identity.value = res.Data;
                    console.log(Identity.value);
                    istan.value=false;
                }else{
                  Toast(res.returnMessage);
                }
                
            });
        };
        const quXiao = () => {
            istan.value=false;
            proxy.$root.$router.go(-1);  
        };
        const djxh=ref('');
        const DQclk = (values,index) =>{
            console.log(values);
            djxh.value = values.DJXH;

            let t_citems =document.getElementsByClassName("citem");
            if(t_citems!=undefined&&t_citems.length>0){
              for (const t_citem of t_citems) {
                t_citem.style.backgroundColor = "#FFFFFF";
                if(t_citem.getAttribute("tabindex") == (""+index)){
                  t_citem.style.backgroundColor = "#c5d9fb";
                }

              }
            }
          //

        };


        console.log("route.query");
        console.log(proxy.$root.$router.currentRoute.value.query);

        const bdClik = () =>{
            if(djxh.value==''){
                Toast("请至少选择一条数据");
                return
            }
            loginRequest.yh_bdsf({djxh:djxh.value}).then(res=>{
                if (res.returnCode == "00"){
                    // let t_rpms = proxy.$root.$router.currentRoute.value.query;
                    // if(t_rpms!=undefined&&t_rpms.uback == "1"){
                    //   proxy.$root.$router.push({path: t_rpms.ufrom});
                    // }else{
                      // sessionStorage.setItem('isDenglu',true);
                      setLocalStorage({"Authorization": "undefined"});
                      getUserInfo(proxy.$root.$route, store);
                      proxy.$root.$router.go(-1);
                    // }
                }else{
                  Toast(res.returnMessage);
                }

                    
            });
        };


        return{
            Identity,
            bdClik,
            formObj,
            onSubmit,
            istan,
            quXiao,
            DQclk,
            djxh,
            sbdata,
            nsrmc,
            control_nsrsbh,
            control_nsrmc,
        }
    }
}
</script>

<style scoped>

    .main {
        /* margin: 0; */
        padding:50px 10px 10px;
        min-height: 100vh;
    }
    
    .qjListItem {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .spanTitle {
        min-width: 101px;
    }
    .spanVLE{
        text-align: right;
    }
</style>